import { Quest } from "../components/Quests/types";
import { fetchClient } from "./fetchClient";


export const questService = {
  listQuests: async (): Promise<Quest[]> => {
    const response = await fetchClient.get<Quest[]>(`/quest`);
    return response.data;
  },

  createQuest: async (quest: Partial<Quest>): Promise<Quest> => {
    const response = await fetchClient.post<Quest>(`/quest`, quest);

    return response.data;
  },

  updateQuest: async (id: string, quest: Partial<Quest>): Promise<Quest> => {
    const response = await fetchClient.put<Quest>(`/quest/${id}`, quest);
    return response.data;
  },

  deleteQuest: async (id: string): Promise<void> => {
    await fetchClient.delete(`/quest/${id}`);
  },
};
