import { useState } from "react";
import { Network } from "../enums/network.enum";
import { refreshWhitelist } from "../services/reservoir.service";

const Collections = () => {
  const [network, setNetwork] = useState<Network>(1);
  const [address, setAddress] = useState<string>("");
  const [answer, setAnswer] = useState<string>("");

  const handleNetworkSelection = (e: any) => {
    const value = e.target.value;
    setNetwork(value);
  };

  const handleAddress = (e: any) => {
    const value = e.target.value;
    setAddress(value);
  };

  const onSubmit = async () => {
    const res = await refreshWhitelist(
      address.trim().toLowerCase(),
      network
    );
    setAnswer(res);
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-xl font-semibold text-gray-800 mb-6">Collection</h2>

      <div className="space-y-4">
        <div className="space-y-2">
          <select
            onChange={handleNetworkSelection}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white"
          >
            <option value={Network.Ethereum}>Ethereum</option>
            <option value={Network.Polygon}>Polygon</option>
          </select>
        </div>

        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Collection address
          </label>
          <input
            onChange={handleAddress}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          />
        </div>

        <button
          onClick={onSubmit}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
        >
          Submit
        </button>

        {answer && (
          <div className="mt-4 p-3 bg-gray-50 rounded-md text-gray-700">
            {answer}
          </div>
        )}
      </div>
    </div>
  );
};

export default Collections;