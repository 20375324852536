import React from 'react'
import UpdateUserBot from './UpdateUserBot'
import UserMessages from './UserMessages'

const SocialAdmin = () => {
    return (
        <>
            <div className="bg-white rounded-lg shadow p-6">
                <h1 className="text-xl font-semibold text-gray-800">Social Admin</h1>
            </div>
            <UpdateUserBot />
            <UserMessages />
        </>
    )
}

export default SocialAdmin