import React, { useState } from "react";
import { setUserRight } from "../services/user.service";

export default function UserRight() {
  const [userInfo, setUserInfo] = useState<string>("");
  const [answer, setAnswer] = useState<string>("");

  const handleOnChange = (e: any) => {
    setUserInfo(e.target.value);
  };

  const onSubmit = async () => {
    const res = await setUserRight(userInfo);
    setAnswer(res.message);
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-2xl font-bold mb-2 text-gray-800">User Right</h2>
      <h5 className="text-sm text-gray-600 mb-6">
        You can give permission to user to create more than 5 pages.
      </h5>

      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          User wallet or email
        </label>
        <input
          type="text"
          onChange={handleOnChange}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Enter user wallet or email"
        />
      </div>

      <button
        onClick={onSubmit}
        className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
      >
        Submit
      </button>

      {answer && (
        <div className="mt-6">
          <div className="p-3 bg-gray-50 rounded-md text-gray-700">
            {answer}
          </div>
        </div>
      )}
    </div>
  );
}