import { useState } from "react";
import { setWhitelistByEmail } from "../services/user.service";

function stringToArray(input: string): string[] {
  return input
    .split("\n")
    .map((a: string) => a.trim())
    .map((a: string) => a.toLowerCase());
}

const WhitelistByEmail = () => {
  const [emails, setEmails] = useState<string>("");
  const [answers, setAnswers] = useState<string[]>([]);

  const handleAddresses = (e: any) => {
    setEmails(e.target.value);
  };

  const onSubmit = async () => {
    const array = stringToArray(emails);

    const results = [];
    for (let index = 0; index < array.length; index++) {
      const element = array[index];

      try {
        const res = await setWhitelistByEmail(element);
        results.push(res.message);
      } catch (error: any) {
        results.push(error);
      }
    }
    setAnswers([...results]);
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-2xl font-bold mb-6 text-gray-800">Whitelist by Email</h2>

      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700 mb-2">
          Emails
        </label>
        <textarea
          rows={20}
          onChange={handleAddresses}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
          placeholder="Enter email addresses (one per line)"
        />
      </div>

      <button
        onClick={onSubmit}
        className="w-full bg-blue-600 text-white py-2 px-4 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
      >
        Submit
      </button>

      {answers.length > 0 && (
        <div className="mt-6 space-y-2">
          {answers.map((answer: any) => (
            <div
              key={answer}
              className="p-3 bg-gray-50 rounded-md text-gray-700"
            >
              {answer}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default WhitelistByEmail;