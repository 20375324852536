import { Page } from "../components/Pages";
import { fetchClient, IDefaultResponse } from "./fetchClient";



export const searchPages = async (slug: string): Promise<Page[]> => {
  const res = await fetchClient.post<{ pages: Page[] }>(`/page/search`, { search: slug });
  return res.data.pages;
};

export const editPage = async (page: Page): Promise<string> => {
  const res = await fetchClient.put<IDefaultResponse>(`/page/update`, { page });
  return res.data.message;
};
export const getPageOwner = async (slug: string): Promise<string> => {
  const res = await fetchClient.get<string>(`/page/get-page-owner/${slug}`);
  return res.data;
};
export const transferPage = async (
  userInfo: string,
  slug: string,
) => {
  const res = await fetchClient.put<IDefaultResponse>(`/page/transfer`, { userInfo, slug });
  return res.data.message;
};
export const addCoOwner = async (
  id: string,
  address: string,
): Promise<string> => {
  const res = await fetchClient.put<IDefaultResponse>(`/page/add-co-owner/${id}`, { address });
  return res.data.message;
};
