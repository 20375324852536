import { useState } from "react";
import { setWhitelist } from "../services/user.service";

function stringToArray(input: string): string[] {
  return input
    .split("\n")
    .map((a: string) => a.trim())
    .map((a: string) => a.toLowerCase());
}

const Whitelist = () => {
  const [addresses, setAddresses] = useState<string>("");
  const [answers, setAnswers] = useState<string[]>([]);

  const handleAddresses = (e: any) => {
    setAddresses(e.target.value);
  };

  const onSubmit = async () => {
    const array = stringToArray(addresses);

    const results = [];
    for (let index = 0; index < array.length; index++) {
      const element = array[index];

      try {
        const res = await setWhitelist(element);
        results.push(res.message);
      } catch (error: any) {
        results.push(error);
      }
    }
    setAnswers([...results]);
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">Whitelist</h2>

      <div className="space-y-2">
        <label className="block text-sm font-medium text-gray-700">
          Addresses
        </label>
        <textarea
          rows={10}
          onChange={handleAddresses}
          className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 resize-none"
          placeholder="Enter addresses (one per line)"
        />
      </div>

      <button
        onClick={onSubmit}
        className="mt-4 w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
      >
        Submit
      </button>

      {answers.length > 0 && (
        <div className="mt-4 space-y-2">
          {answers.map((answer: any) => (
            <div
              key={answer}
              className="p-3 bg-gray-50 rounded-md text-gray-700 text-sm"
            >
              {answer}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Whitelist;