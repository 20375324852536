import React, { useCallback, useState } from "react";
import { refreshAirtableDatas } from "../services/airtable.service";

export default function Airtable() {
  const [message, setMessage] = useState<string>("");
  const [disable, setDisable] = useState<boolean>(false);

  const handleRefresh = useCallback(async () => {
    setDisable(true);
    setMessage("");
    const res = await refreshAirtableDatas();
    setMessage(res);
    setDisable(false);
  }, [setMessage]);

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-xl font-semibold text-gray-800 mb-6">Airtable</h2>

      <div className="space-y-4">
        {disable && (
          <div className="p-4 bg-blue-50 border border-blue-200 rounded-md text-blue-700">
            Refresh in progress, take a coffee break. It can take a couple of minutes.
          </div>
        )}

        {message && (
          <div className="p-4 bg-gray-50 rounded-md text-gray-700">
            {message}
          </div>
        )}

        <button
          onClick={handleRefresh}
          disabled={disable}
          className={`w-full py-2 px-4 rounded-md transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2
            ${disable
              ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
              : 'bg-blue-500 text-white hover:bg-blue-600'
            }`}
        >
          {disable ? 'Refreshing...' : 'Refresh'}
        </button>
      </div>
    </div>
  );
}