export interface Base {
  id: string;
  createdAt: string;
  updatedAt: string;
}

export enum QuestTypes {
  ONBOARDING_QUESTS = "ONBOARDING_QUESTS",
  DAILY_QUESTS = "DAILY_QUESTS",
  MAIN_QUESTS = "MAIN_QUESTS",
  MILESTONE_QUESTS = "MILESTONE_QUESTS",
}

export enum QuestCTACategories {
  TWITTER_CONTENT = "TWITTER_CONTENT",
  DISCORD_CONTENT = "DISCORD_CONTENT",
  WEBSITE_CONTENT = "WEBSITE_CONTENT",
  WALLET_CONTENT = "WALLET_CONTENT",
  MILESTONE_CONTENT = "MILESTONE_CONTENT",
}

export enum QuestCTAIdentifiers {
  TWITTER_FOLLOW = "TWITTER_FOLLOW",
  TWITTER_LIKE = "TWITTER_LIKE",
  TWITTER_RETWEET = "TWITTER_RETWEET",
  TWITTER_COMMENT = "TWITTER_COMMENT",
  TWITTER_LIKE_LAST_TWEET = "TWITTER_LIKE_LAST_TWEET",
  TWITTER_RETWEET_LAST_TWEET = "TWITTER_RETWEET_LAST_TWEET",
  TWITTER_COMMENT_LAST_TWEET = "TWITTER_COMMENT_LAST_TWEET",
  TWITTER_CREATE_POST = "TWITTER_CREATE_POST",
  TWITTER_JOIN_SPACE = "TWITTER_JOIN_SPACE",
  DISCORD_LINK_ACCOUNT = "DISCORD_LINK_ACCOUNT",
  DISCORD_JOIN_SERVER = "DISCORD_JOIN_SERVER",
  DISCORD_SEND_MESSAGE = "DISCORD_SEND_MESSAGE",
  WEBSITE_VISIT_PAGE = "WEBSITE_VISIT_PAGE",
  WEBSITE_COMPLETE_FORM = "WEBSITE_COMPLETE_FORM",
  WALLET_CONNECTION = "WALLET_CONNECTION",
  MILESTONE_COMPLETE_QUESTS = "MILESTONE_COMPLETE_QUESTS",
  MILESTONE_COMPLETE_DAILY_QUESTS = "MILESTONE_COMPLETE_DAILY_QUESTS",
  MILESTONE_COMPLETE_ONBOARDING_QUESTS = "MILESTONE_COMPLETE_ONBOARDING_QUESTS",
  MILESTONE_COMPLETE_MAIN_QUESTS = "MILESTONE_COMPLETE_MAIN_QUESTS",
  MILESTONE_REFER_FRIENDS = "MILESTONE_REFER_FRIENDS",
  MILESTONE_LIKE_HUB_TWEETS = "MILESTONE_LIKE_HUB_TWEETS",
}

export interface TwitterCTAData {
  identifier: QuestCTAIdentifiers;
  tweetLink: string | null;
  twitterAccount: string | null;
  isOnHubFeed: boolean | null;
}

export interface DiscordCTAData {
  identifier: QuestCTAIdentifiers;
  serverId: string;
  channelId: string | null;
}

export interface WebsiteCTAData {
  identifier: QuestCTAIdentifiers;
  url: string;
}

export interface WalletCTAData {
  identifier: QuestCTAIdentifiers;
  requiredNetwork: string | null;
}

// Should be an or condition of the above
export type QuestCTAData =
  | TwitterCTAData
  | DiscordCTAData
  | WebsiteCTAData
  | WalletCTAData;

export interface Quest extends Base {
  description: string;
  type: QuestTypes;
  totalChecks: number;
  validFrom: Date;
  validTo: Date | null;
  isEnabled: boolean;
  score: number;
  verificationCode: string | null;
  ctaCategory: QuestCTACategories;
  ctaIdentifier: QuestCTAIdentifiers;
  ctaData: QuestCTAData;
}
