import React, { useCallback, useState } from "react";
import { getPageOwner } from "../services/page.service";

export default function PageOwner() {
  const [url, setUrl] = useState<string>("");
  const [owner, setOwner] = useState<any>(null);

  const handleOnChange = useCallback((event: any) => {
    const url = event.target.value;
    setUrl(url);
  }, []);

  const handleOnClick = useCallback(async () => {
    const slug = url.trim().split("/");
    const owner = await getPageOwner(slug[slug.length - 1]);
    setOwner(owner);
  }, [url]);

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-xl font-semibold text-gray-800 mb-4">Page ownership</h2>

      <div className="space-y-4">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Enter the page URL
          </label>
          <div className="flex gap-3">
            <input
              type="text"
              onChange={handleOnChange}
              className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter page URL"
            />
            <button
              onClick={handleOnClick}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
            >
              Submit
            </button>
          </div>
        </div>

        {owner && (
          <div className="mt-6 p-4 bg-gray-50 rounded-lg space-y-2">
            <div className="font-medium text-gray-800">Owner of the page:</div>
            <div className="text-gray-700">Address: {owner.accounts}</div>
            {owner.authType === 'email' && (
              <div className="text-gray-700">Email: {owner.email}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}