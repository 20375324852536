import React, { useState } from "react";
import { editPage, searchPages, transferPage } from "../services/page.service";
import update from "immutability-helper";
import { AddCoOwner } from "./AddCoOwner";

export interface Page {
  title: string;
  slug: string;
  url: string;
  id: string;
}

export default function Pages() {
  const [slug, setSlug] = useState<string>("");
  const [pages, setPages] = useState<Page[]>([]);
  const [error, setError] = useState<string>("");
  const [selectedPage, setSelectedPage] = useState<Page | null>(null);
  const [pageForCoOwner, setPageForCoOwner] = useState<Page | null>(null);
  const [userInfo, setUserInfo] = useState<string>("");
  const [transferResponse, setTransferResponse] = useState<string>("");

  const handleSearch = (e: any) => {
    setSlug(e.target.value);
  };

  const onSubmit = async () => {
    setError("");
    const pages = await searchPages(slug);
    setPages(pages);
  };

  const handleEditRow = async (index: number) => {
    const pageToEdit = pages[index];
    const res = await editPage(pageToEdit);
    setError(res);
  };

  const handleOnChangeTitle = (value: string, index: number) => {
    const tmp = pages;
    var updatedPage = update(tmp[index], { title: { $set: value } });
    var newPages = update(tmp, {
      $splice: [[index, 1, updatedPage]],
    });
    setPages([...newPages]);
  };

  const handleOnChangeSlug = (value: string, index: number) => {
    const tmp = pages;
    var updatedPage = update(tmp[index], {
      slug: { $set: value },
      url: { $set: "/" + value },
    });
    var newPages = update(tmp, {
      $splice: [[index, 1, updatedPage]],
    });
    setPages([...newPages]);
  };

  const handleUserInfo = (e: any) => {
    const userInfo: string = e.target.value.toLowerCase();
    setUserInfo(userInfo);
  };

  const onClickTransfer = async () => {
    if (selectedPage) {
      const res = await transferPage(userInfo, selectedPage.slug);
      setTransferResponse(res);
    }
  };

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-xl font-semibold text-gray-800 mb-6">Pages</h2>

      <div className="space-y-6">
        <div className="space-y-2">
          <label className="block text-sm font-medium text-gray-700">
            Search pages
          </label>
          <div className="flex gap-3">
            <input
              onChange={handleSearch}
              className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter search term"
            />
            <button
              onClick={onSubmit}
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
            >
              Search
            </button>
          </div>
        </div>

        {error && (
          <div className="p-3 bg-red-50 border border-red-200 rounded-md text-red-600">
            {error}
          </div>
        )}

        {pages.length > 0 && (
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Title</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Slug</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">URL</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {pages.map((p: Page, index: number) => (
                  <tr key={p.id}>
                    <td className="px-6 py-4">
                      <input
                        onChange={(e) => handleOnChangeTitle(e.target.value, index)}
                        defaultValue={p.title}
                        className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </td>
                    <td className="px-6 py-4">
                      <input
                        onChange={(e) => handleOnChangeSlug(e.target.value, index)}
                        defaultValue={p.slug}
                        className="w-full px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                      />
                    </td>
                    <td className="px-6 py-4">
                      <input
                        value={p.url}
                        disabled={true}
                        className="w-full px-2 py-1 border border-gray-200 rounded-md bg-gray-50"
                      />
                    </td>
                    <td className="px-6 py-4 space-x-2">
                      <button
                        onClick={() => handleEditRow(index)}
                        className="text-blue-600 hover:text-blue-800 font-medium"
                      >
                        Edit
                      </button>
                      <button
                        onClick={() => setSelectedPage(p)}
                        className="text-blue-600 hover:text-blue-800 font-medium"
                      >
                        Transfer
                      </button>
                      <button
                        onClick={() => setPageForCoOwner(p)}
                        className="text-blue-600 hover:text-blue-800 font-medium"
                      >
                        Add Co-owner
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}

        {selectedPage && (
          <div className="mt-8 p-6 bg-gray-50 rounded-lg">
            <h3 className="text-lg font-medium text-gray-800 mb-4">
              Transfer {selectedPage.slug} to:
            </h3>
            <div className="flex gap-3">
              <input
                type="text"
                placeholder="Paste wallet address or email here"
                onChange={handleUserInfo}
                className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
              <button
                onClick={onClickTransfer}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
              >
                Transfer
              </button>
            </div>

            {transferResponse && (
              <div className="mt-4 p-3 bg-white rounded-md text-gray-700">
                {transferResponse}
              </div>
            )}
          </div>
        )}

        {pageForCoOwner && <AddCoOwner page={pageForCoOwner} />}
      </div>
    </div>
  );
};