import React, { useState, useEffect } from "react";
import { questService } from "../../services/quest.service";
import { Quest } from "./types";
import CreateEditQuestForm from "./CreateEditQuestFom";
import QuestList from "./QuestList";

const QuestManager: React.FC = () => {
  const [quests, setQuests] = useState<Quest[]>([]);
  const [formData, setFormData] = useState<Partial<Quest>>({});
  const [editingId, setEditingId] = useState<string | null>(null);

  useEffect(() => {
    fetchQuests();
  }, []);

  const fetchQuests = async () => {
    try {
      const fetchedQuests = await questService.listQuests();
      setQuests(fetchedQuests);
    } catch (error) {
      console.error("Error fetching quests:", error);
    }
  };

  const handleSubmit = async (questData: Partial<Quest>) => {
    try {
      if (editingId) {
        await questService.updateQuest(editingId, questData);
      } else {
        await questService.createQuest(questData);
      }
      fetchQuests();
      setFormData({});
      setEditingId(null);
    } catch (error) {
      console.error("Error saving quest:", error);
    }
  };

  const handleEdit = (quest: Quest) => {
    setFormData(quest);
    setEditingId(quest.id);
  };

  const handleDelete = async (id: string) => {
    try {
      await questService.deleteQuest(id);
      fetchQuests();
    } catch (error) {
      console.error("Error deleting quest:", error);
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <h1 style={{ marginBottom: "20px" }}>Quest Manager</h1>
      <CreateEditQuestForm
        formData={formData}
        setFormData={setFormData}
        onSubmit={handleSubmit}
        editingId={editingId}
      />
      <QuestList quests={quests} onEdit={handleEdit} onDelete={handleDelete} />
    </div>
  );
};

export default QuestManager;
