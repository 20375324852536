import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react';

interface ApiKeyContextType {
    apiKey: string;
    updateApiKey: (key: string) => void;
    clearApiKey: () => void;
}

const ApiKeyContext = createContext<ApiKeyContextType | undefined>(undefined);

interface ApiKeyProviderProps {
    children: ReactNode;
}

export const ApiKeyProvider: React.FC<ApiKeyProviderProps> = ({ children }) => {
    const [apiKey, setApiKey] = useState<string>(localStorage.getItem('apiKey') ?? '');

    useEffect(() => {
        if (apiKey) {
            localStorage.setItem('apiKey', apiKey);
        } else {
            localStorage.removeItem('apiKey');
        }
    }, [apiKey]);

    const updateApiKey = (newKey: string) => {
        setApiKey(newKey);
    };

    const clearApiKey = () => {
        setApiKey('');
    };

    return (
        <ApiKeyContext.Provider value={{ apiKey, updateApiKey, clearApiKey }}>
            {children}
        </ApiKeyContext.Provider>
    );
};

export const useApiKey = (): ApiKeyContextType => {
    const context = useContext(ApiKeyContext);
    if (!context) {
        throw new Error('useApiKey must be used within an ApiKeyProvider');
    }
    return context;
};