import React, { useState } from 'react'
import { addCoOwner } from '../services/page.service';
import { Page } from './Pages';

interface IAddCoOwnerProps {
    page: Page;
}

export const AddCoOwner = ({ page }: IAddCoOwnerProps) => {
    const [wallet, setWallet] = useState<string>("");
    const [response, setResponse] = useState<string>("")

    const handleWalletAddress = (event: any) => {
        const value = event.target.value;
        setWallet(value);
    }

    const onSubmit = async () => {
        const res = await addCoOwner(page.id, wallet);
        setResponse(res);
    }

    return (
        <div className="bg-white rounded-lg shadow p-6 mt-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">
                Add Co-owner to page {page.slug}
            </h2>

            <div className="flex gap-3">
                <input
                    type="text"
                    placeholder="Paste wallet address here"
                    onChange={handleWalletAddress}
                    className="flex-1 px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
                <button
                    onClick={onSubmit}
                    className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-colors duration-200"
                >
                    Add
                </button>
            </div>

            {response && (
                <div className="mt-4 p-3 bg-gray-50 rounded-md text-gray-700">
                    {response}
                </div>
            )}
        </div>
    )
}