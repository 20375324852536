import { Network } from "../enums/network.enum";
import { fetchClient, IDefaultResponse } from "./fetchClient";

export const refreshWhitelist = async (
  address: string,
  network: Network,
): Promise<string> => {
  const res = await fetchClient.post<IDefaultResponse>(`/collection/refresh`, { address: address, network: network});
  return res.data.message;
};
