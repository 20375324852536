import React, { useEffect, useState } from "react";
import { fetchUserFlaggedBot, softDeleteUser, UpdateUserBot, updateUserPoints } from "../../services/user-social.service";

interface BotUser {
    twitterUsername: string;
    isBot: boolean;
    isBotFlaggedDate: string;
    softDeleted: boolean;
    pointsEarned: number;
}

interface EditablePoints {
    username: string;
    points: string;
    isEditing: boolean;
}

export default function UpdateUserBotComponent() {
    const [users, setUsers] = useState<BotUser[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>("");
    const [updateMessage, setUpdateMessage] = useState<string>("");
    const [pointsEdit, setPointsEdit] = useState<EditablePoints | null>(null);

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const data = await fetchUserFlaggedBot();
            setUsers(data);
            setError("");
        } catch (err) {
            setError("Failed to fetch users");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    const handleBotStatusChange = async (username: string, newStatus: boolean) => {
        try {
            setUpdateMessage("");
            await UpdateUserBot(username, newStatus);
            setUpdateMessage(`Successfully updated status for ${username}`);
            fetchUsers();
        } catch (err) {
            setError("Failed to update user status");
        }
    };

    const handleSoftDelete = async (username: string) => {
        try {
            await softDeleteUser(username);
            setUpdateMessage(`Successfully deleted user ${username}`);
            fetchUsers();
        } catch (err) {
            setError("Failed to delete user");
        }
    };

    const startEditingPoints = (username: string, currentPoints: number) => {
        setPointsEdit({
            username,
            points: currentPoints.toString(),
            isEditing: true
        });
    };

    const handlePointsChange = (value: string) => {
        if (pointsEdit) {
            setPointsEdit({
                ...pointsEdit,
                points: value.replace(/[^0-9]/g, '') // Only allow numbers
            });
        }
    };

    const savePoints = async () => {
        if (!pointsEdit) return;

        try {
            await updateUserPoints(pointsEdit.username, parseInt(pointsEdit.points));
            setUpdateMessage(`Successfully updated points for ${pointsEdit.username}`);
            setPointsEdit(null);
            fetchUsers();
        } catch (err) {
            setError("Failed to update points");
        }
    };

    const cancelPointsEdit = () => {
        setPointsEdit(null);
    };

    return (
        <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-6">Bot Users Management</h2>

            {loading && (
                <div className="text-center py-4">
                    <div className="animate-pulse text-gray-600">Loading users...</div>
                </div>
            )}

            {error && (
                <div className="mb-4 p-3 bg-red-50 border border-red-200 rounded-md text-red-600">
                    {error}
                </div>
            )}

            {updateMessage && (
                <div className="mb-4 p-3 bg-green-50 border border-green-200 rounded-md text-green-600">
                    {updateMessage}
                </div>
            )}

            {!loading && users.length === 0 && (
                <div className="text-center py-4 text-gray-500">
                    No bot users found
                </div>
            )}

            {users.length > 0 && (
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                            <tr>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Username
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Bot Status
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Points Earned
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Flagged Date
                                </th>
                                <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                    Actions
                                </th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {users.map((user) => (
                                <tr key={user.twitterUsername}>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                            <a
                                                href={`https://twitter.com/${user.twitterUsername}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-blue-600 hover:text-blue-800"
                                            >
                                                @{user.twitterUsername}
                                            </a>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="flex items-center">
                                            <label className="inline-flex items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    checked={user.isBot}
                                                    onChange={(e) => handleBotStatusChange(user.twitterUsername, e.target.checked)}
                                                    className="form-checkbox h-4 w-4 text-blue-600 transition duration-150 ease-in-out"
                                                />
                                                <span className="ml-2 text-gray-700">
                                                    {user.isBot ? "Bot" : "Not Bot"}
                                                </span>
                                            </label>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        {pointsEdit?.username === user.twitterUsername ? (
                                            <div className="flex items-center space-x-2">
                                                <input
                                                    type="text"
                                                    value={pointsEdit.points}
                                                    onChange={(e) => handlePointsChange(e.target.value)}
                                                    className="w-24 px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                                                />
                                                <button
                                                    onClick={savePoints}
                                                    className="text-green-600 hover:text-green-800"
                                                >
                                                    Save
                                                </button>
                                                <button
                                                    onClick={cancelPointsEdit}
                                                    className="text-gray-600 hover:text-gray-800"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        ) : (
                                            <div
                                                onClick={() => startEditingPoints(user.twitterUsername, user.pointsEarned)}
                                                className="cursor-pointer hover:text-blue-600"
                                            >
                                                {user.pointsEarned}
                                            </div>
                                        )}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                                        {user.isBotFlaggedDate
                                            ? new Date(user.isBotFlaggedDate).toLocaleDateString()
                                            : "Not flagged"
                                        }
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <button
                                            onClick={() => handleSoftDelete(user.twitterUsername)}
                                            className="text-red-600 hover:text-red-800 font-medium"
                                        >
                                            Delete
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}
        </div>
    );
}