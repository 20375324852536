import React, { useState } from 'react';
import { useApiKey } from '../context/ApiKeyContext';

export const ApiKeyInput: React.FC = () => {
    const { apiKey, updateApiKey } = useApiKey();
    const [inputKey, setInputKey] = useState<string>(apiKey);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        updateApiKey(inputKey);
    };

    const handleClear = () => {
        setInputKey('');
        updateApiKey('');
    };

    return (
        <div className="p-4 border rounded shadow">
            <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                    <label htmlFor="apiKey" className="block text-sm font-medium text-gray-700">
                        API Key
                    </label>
                    <input
                        type="text"
                        id="apiKey"
                        value={inputKey}
                        onChange={(e) => setInputKey(e.target.value)}
                        placeholder="Enter your API key"
                        className="mt-1 block w-full border rounded-md shadow-sm p-2"
                    />
                </div>
                <div className="flex space-x-2">
                    <button
                        type="submit"
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    >
                        Save API Key
                    </button>
                    <button
                        type="button"
                        onClick={handleClear}
                        className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                    >
                        Clear
                    </button>
                </div>
            </form>
        </div>
    );
};