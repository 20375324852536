import { fetchClient, IDefaultResponse } from "./fetchClient";

export const setWhitelist = async (address: string): Promise<IDefaultResponse> => {
  const res = await fetchClient.post<IDefaultResponse>(`/user/whitelist`, { address: address });
  return res.data;
};
export const setWhitelistByEmail = async (email: string): Promise<IDefaultResponse> => {
  const res = await fetchClient.post<IDefaultResponse>(`/user/whitelist-by-email`, { email: email })
  return res.data;
};
export const setUserRight = async (userInfo: string,): Promise<IDefaultResponse> => {
  const res = await fetchClient.post<IDefaultResponse>(`/user/set-right`, { info: userInfo });
  return res.data;
};
